import { type ClassValue, clsx } from 'clsx';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { parseISO } from 'date-fns/parseISO';
import { twMerge } from 'tailwind-merge';
import { v4 as uuidv4 } from 'uuid';

import { Eat_ClientType } from './__generated__/graphql';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

let anonId: string | null = null;

export function getAnonId() {
  if (anonId) return anonId;

  anonId = localStorage.getItem('anonId');

  if (anonId) return anonId;

  anonId = uuidv4();
  localStorage.setItem('anonId', anonId);
  return anonId;
}

export function rappenToFrancs(rappen: number) {
  return (rappen / 100).toFixed(2);
}

export function getShowWarningTime() {
  const lastDismissedWarningTime = localStorage.getItem(
    'lastDismissedWarningTime',
  );
  if (!lastDismissedWarningTime) {
    return true;
  }

  const now = new Date();
  const lastDismissedWarningTimeDate = parseISO(lastDismissedWarningTime);

  if (differenceInMinutes(now, lastDismissedWarningTimeDate) > 20) {
    return true;
  }

  return false;
}

export function wait(ms: number) {
  return new Promise((res) => setTimeout(res, ms));
}

export function neverHappens(thisThingIsNeverPossible: never) {
  throw new Error(
    `This code should be unreachable, but the value that was never to exists was:0 ${thisThingIsNeverPossible}`,
  );
}

export function isErrorWithMessage(err: unknown): err is { message: string } {
  return Boolean(
    err &&
      typeof err === 'object' &&
      'message' in err &&
      typeof err.message === 'string',
  );
}

declare global {
  interface Window {
    isInWebView: boolean | undefined;
  }
}

export function isInWebView() {
  return window.isInWebView ?? false;
}

export function getClientType(): Eat_ClientType {
  return isInWebView() ? 'APP' : 'WEB';
}
