import { PhoneIcon, SendIcon, User } from 'lucide-react';
import { ComponentProps, ReactNode, useCallback, useEffect } from 'react';
import { toast } from 'sonner';

import { TableIcon } from '@/components/common/icons/table-icon';
import { OrderFooter } from '@/components/common/order-footer';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { useTranslation } from '@/i18n/client';
import { useNavigate, useParamsRequired } from '@/lib/navigation';
import { cn } from '@/lib/utils';
import { useMainStore } from '@/stores/main-store';
import {
  requestPushPermission,
  usePushPermissionStore,
} from '@/stores/push-permission-store';
import { useUserProfile } from '@/stores/user-profile-store';

const formNames = {
  name: 'name',
  tableNumber: 'tableNumber',
  phone: 'phone',
  comment: 'comment',
};

export function OrderDetailsPage() {
  const { t } = useTranslation();
  const { restaurantId } = useParamsRequired('restaurantId');
  const { userProfile } = useUserProfile();

  const {
    cart,
    menu,
    restaurant,
    tableNumber,
    setTableNumber,
    phoneNumber,
    setPhoneNumber,
  } = useMainStore((state) => ({
    cart: state.cart,
    menu: state.menu,
    restaurant: state.restaurant,
    tableNumber: state.tableNumber,
    setTableNumber: state.setTableNumber,
    phoneNumber: state.phoneNumber,
    setPhoneNumber: state.setPhoneNumber,
  }));

  const [nameOnReceipt, setNameOnReceipt] = useMainStore((state) => [
    state.nameOnReceipt,
    state.setNameOnReceipt,
  ]);

  const [commentsByRestaurantId, setCommentsForRestaurantId] = useMainStore(
    (state) => [state.commentsByRestaurantId, state.setCommentsForRestaurantId],
  );

  const { pushAvailable, pushPermissionGranted } = usePushPermissionStore();

  const navigate = useNavigate();

  const validateTableNumber = useCallback(() => {
    if (
      tableNumber &&
      restaurant?.tableNumbers &&
      !restaurant.tableNumbers.includes(tableNumber)
    ) {
      toast.error(
        t(
          'Details - Not a valid table number',
          'Sorry, that’s not a valid table number for {{restaurantName}}',
          { restaurantName: restaurant.name },
        ),
        { duration: 3000 },
      );
      setTableNumber('');
      return false;
    }
    return true;
  }, [tableNumber]);

  const onPhoneBlur = async (
    ev: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    const result = await validatePhoneNumber(ev.currentTarget.value);
    if (result && result.isValid()) {
      return setPhoneNumber(result.format('E.164'));
    }
    toast.error(
      t(
        'Details - Not a valid phone number',
        'Phone number is not valid, try starting with country code',
      ),
      { duration: 3000 },
    );
  };

  useEffect(() => {
    if (!nameOnReceipt && userProfile?.firstName) {
      setNameOnReceipt(userProfile.firstName);
    }
  }, [userProfile?.firstName]);

  if (menu === null || cart === null) {
    return null;
  }

  return (
    <form
      onSubmit={async (ev) => {
        ev.preventDefault();
        const phoneInput = ev.currentTarget.elements.namedItem(
          formNames.phone,
        ) as HTMLInputElement;

        if (phoneInput) {
          const phoneNumber = await validatePhoneNumber(phoneInput.value);
          if (!phoneNumber || !phoneNumber.isValid()) {
            phoneInput.setCustomValidity('Invalid phone number');
            phoneInput.reportValidity();
            return;
          }
          setPhoneNumber(phoneNumber.format('E.164'));
        }

        if (restaurant?.diningMode === 'TABLE' && !validateTableNumber()) {
          return;
        }

        if (!nameOnReceipt) {
          return;
        }

        navigate(`/${restaurantId}/checkout`);
      }}
    >
      <main className="mt-20 flex flex-col gap-6 px-5">
        <p className="text-md">
          {t(
            'Details - Title',
            'This information will help you get your order to the right place.',
          )}
        </p>

        <div className="flex flex-col gap-3">
          <p className="text-md font-medium leading-5">
            {t('Details - Your details label', 'Your details*')}
          </p>

          {restaurant?.diningMode === 'TABLE' && (
            <IconInput
              icon={<TableIcon className="-mr-[0.2rem] size-5" />}
              required
              inputMode="numeric"
              value={tableNumber ?? ''}
              onChange={(e) => {
                setTableNumber(e.target.value);
              }}
              onBlur={validateTableNumber}
              placeholder={t(
                'Details - Table number placeholder',
                'Table number',
              )}
            />
          )}
          <IconInput
            icon={<User className="size-4 shrink-0" />}
            required
            className="border-none px-0 focus-visible:ring-0 focus-visible:ring-offset-0"
            value={nameOnReceipt}
            onChange={(e) => {
              setNameOnReceipt(e.target.value);
            }}
            placeholder={t('Details - Your name placeholder', 'Your name')}
          />
          {restaurant?.diningMode !== 'TABLE' && (
            <IconInput
              name="phone"
              icon={<PhoneIcon className="mt-0.5 size-4" />}
              required
              type="tel"
              autoComplete="tel"
              inputMode="tel"
              value={phoneNumber ?? ''}
              onBlur={onPhoneBlur}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              placeholder={t(
                'Details - Phone number placeholder',
                'Phone number*',
              )}
            />
          )}

          <Textarea
            className="h-24 rounded-md p-3 focus-visible:ring-0 focus-visible:ring-offset-0"
            placeholder={t(
              'Details - Comments placeholder',
              'Comments (optional)',
            )}
            value={commentsByRestaurantId[restaurantId] ?? ''}
            onChange={(e) => {
              setCommentsForRestaurantId(e.target.value, restaurantId);
            }}
          />

          {restaurant?.diningMode !== 'TABLE' && pushAvailable && (
            <PushPermissionBlock />
          )}
        </div>
      </main>

      <OrderFooter
        buttonText={t('Cart summary - Button - At details page', 'To checkout')}
        stepNumber={3}
        onButtonClick={() => {}}
        buttonDisabled={
          restaurant?.diningMode !== 'TABLE' &&
          pushAvailable &&
          !pushPermissionGranted
        }
      />
    </form>
  );
}

function IconInput({
  icon,
  ...props
}: { icon: ReactNode } & ComponentProps<typeof Input>) {
  return (
    <div className="flex w-full items-center gap-3 rounded-md border px-2 has-[:focus]:border-border">
      {icon}
      <Input
        {...props}
        className={cn(
          'border-none px-0 focus-visible:ring-0 focus-visible:ring-offset-0',
          props.className,
        )}
      />
    </div>
  );
}

function PushPermissionBlock() {
  const { t } = useTranslation();

  const { pushPermissionGranted, canAskForPushPermissionAgain } =
    usePushPermissionStore();

  return (
    <div className="flex w-full items-center gap-3 rounded-md border px-2 py-2 has-[:focus]:border-border">
      <SendIcon className="ml-1 mt-0.5 size-6" />
      <div className="ml-2 flex flex-1 flex-col gap-1 border-none px-0 focus-visible:ring-0 focus-visible:ring-offset-0">
        <h3 className="text-md font-medium">
          {t('Cart summary - Push notifications', 'Push notifications')}
        </h3>
        {pushPermissionGranted && (
          <p className="mb-2 text-sm">
            {t(
              'Cart summary - Push notifications - Enabled description',
              'You have push notifications enabled. We’ll notify you as soon as your order is ready.',
            )}
          </p>
        )}

        {!pushPermissionGranted && !canAskForPushPermissionAgain && (
          <>
            <p className="mb-2 text-sm">
              {t(
                'Cart summary - Push notifications - Blocked description',
                'You must have push notifications enabled to place an order. Please go to your phone’s settings to enable them for LAAX App.',
              )}
            </p>
            <Button
              variant="primary"
              type="button"
              onClick={requestPushPermission}
            >
              {t(
                'Cart summary - Push notifications - Go to settings button text',
                'Go to settings',
              )}
            </Button>
          </>
        )}

        {!pushPermissionGranted && canAskForPushPermissionAgain && (
          <>
            <p className="mb-2 text-sm">
              {t(
                'Cart summary - Push notifications - Description',
                'Turn on push notifications, and we’ll notify you as soon as your order is ready.',
              )}
            </p>
            <Button
              variant="primary"
              type="button"
              onClick={requestPushPermission}
            >
              {t(
                'Cart summary - Push notifications - Enable push button text',
                'Enable push notifications',
              )}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

const validatePhoneNumber = async (phoneNumber: string) => {
  const libPhone = await import('libphonenumber-js');
  const result = libPhone.parsePhoneNumberFromString(phoneNumber, {
    defaultCountry: 'CH',
  });
  return result;
};
