import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { listOrdersToday } from '@/actions/order/list-orders-today';
import { Order } from '@/actions/order/order-types';
import { getAnonId } from '@/lib/utils';

import { ExpandableOrderInfo } from '../shared-components/order-info';

export function OrdersPage() {
  const [orders, setOrders] = useState<Order[]>([]);

  const [params] = useSearchParams();

  const expanded = params.get('expanded');

  useEffect(() => {
    listOrdersToday(getAnonId(), null).then(setOrders);
  }, []);

  if (orders.length === 0) {
    return <div>{t('Orders page - No orders found', 'No orders found')}</div>;
  }

  return (
    <div className="mt-16 flex flex-col gap-6 px-5">
      {orders.map((order) => {
        return (
          <ExpandableOrderInfo
            key={order.id}
            order={order}
            expanded={order.id === expanded}
          />
        );
      })}
    </div>
  );
}
