import { stripTypename } from '@apollo/client/utilities';
import { Pen, Trash2 } from 'lucide-react';
import { useEffect, useState } from 'react';

import { removeDealInCart } from '@/actions/cart/remove-deal';
import { removeItemInCart } from '@/actions/cart/remove-item';
import { updateItemInCart } from '@/actions/cart/update-item';
import { ListEntry } from '@/components/common/list-entry';
import { QuantitySelector } from '@/components/common/quantity-selector';
import { RoundButtonOutline } from '@/components/common/round-button';
import {
  Eat_CartFragment,
  Eat_Cart_DealFragment,
  Eat_Cart_ItemFragment,
  Eat_Menu,
} from '@/lib/__generated__/graphql';
import { cn, rappenToFrancs } from '@/lib/utils';
import { useMainStore } from '@/stores/main-store';

const cartListItemClass = cn('my-8');

export function CartListItem(props: {
  restaurantId: string;
  cartEntry: Eat_CartFragment['entries'][number];
  menu: Eat_Menu;
}) {
  switch (props.cartEntry.__typename) {
    case 'Eat_Cart_Item':
      return (
        <CartItem
          restaurantId={props.restaurantId}
          cartItem={props.cartEntry}
          menu={props.menu}
        />
      );
    case 'Eat_Cart_Deal':
      return (
        <CartDeal
          restaurantId={props.restaurantId}
          cartDeal={props.cartEntry}
          menu={props.menu}
        />
      );
    default:
      return null;
  }
}

function CartItem(props: {
  restaurantId: string;
  cartItem: Eat_Cart_ItemFragment;
  menu: Eat_Menu;
}) {
  const { setCart } = useMainStore();

  const [quantity, setQuantity] = useState(props.cartItem.quantity);

  useEffect(
    () => setQuantity(props.cartItem.quantity),
    [props.cartItem.quantity],
  );

  const changeItemQuantity = async (quantity: number) => {
    setQuantity(quantity);
    const newCart =
      quantity === 0
        ? await removeItemInCart({
            restaurantId: props.restaurantId,
            id: props.cartItem.id,
          })
        : await updateItemInCart({
            restaurantId: props.restaurantId,
            id: props.cartItem.id,
            quantity,
            modifierGroups: props.cartItem.modifierGroups
              ? stripTypename(props.cartItem.modifierGroups)
              : [],
          });
    setCart(newCart);
  };

  const selectedModifierNames = props.cartItem.modifierGroups
    ?.flatMap((s) => s.modifiers.map((modifier) => modifier.name))
    .join(', ');

  if (quantity === 0) {
    return null;
  }

  return (
    <ListEntry
      name={props.cartItem.menuItem.name}
      imageUrl={props.cartItem.menuItem.imageUrl}
      description={selectedModifierNames}
      className={cartListItemClass}
      imageIcon={<Pen className={'size-3'} />}
      priceText={`${rappenToFrancs(props.cartItem.priceRappen)} CHF`}
      imageLinkHref={`/${props.restaurantId}/cart/entry/${props.cartItem.id}`}
    >
      <QuantitySelector
        onDecrement={async () =>
          await changeItemQuantity(props.cartItem.quantity - 1)
        }
        onIncrement={async () =>
          await changeItemQuantity(props.cartItem.quantity + 1)
        }
        quantity={quantity}
        trashIconsSingleItem
      />
    </ListEntry>
  );
}

function CartDeal(props: {
  restaurantId: string;
  cartDeal: Eat_Cart_DealFragment;
  menu: Eat_Menu;
}) {
  const { setCart } = useMainStore();

  const onRemove = async () => {
    const newCart = await removeDealInCart({
      restaurantId: props.restaurantId,
      dealId: props.cartDeal.id,
      //TODO: Figure out if this has any purpose. A "cartId" should be a combinatory key of anonId+restaurantId.
      //Currently this seems to do nothing, in that case it should be removed from backend schema.
      cartId: '',
    });
    setCart(newCart);
  };

  const formattedSelectedItemNames = props.cartDeal.groups
    .flatMap((group) =>
      group.items
        .filter((item) => item.quantity > 0)
        .map((item) => `${item.menuItem.name}`),
    )
    .join(', ');

  return (
    <ListEntry
      name={props.cartDeal.menuDeal.name}
      imageUrl={props.cartDeal.menuDeal.imageUrl}
      description={formattedSelectedItemNames}
      className={cartListItemClass}
      imageIcon={<Pen className={'size-3'} />}
      priceText={`${rappenToFrancs(props.cartDeal.priceRappen)} CHF`}
      imageLinkHref={`/${props.restaurantId}/cart/entry/${props.cartDeal.id}`}
    >
      <RoundButtonOutline onClick={() => onRemove()}>
        <Trash2 className="size-4" />
      </RoundButtonOutline>
    </ListEntry>
  );
}
