import { useEffect } from 'react';
import { create } from 'zustand';

import { loadUserProfile } from '@/actions/user/load-user-profile';
import { UserProfileFragment } from '@/lib/__generated__/graphql';
import { checkIsAuthenticated } from '@/lib/auth';

// FIXME: Ensure all user data is thrown away if the user is force logged out
export const useUserStore = create<{
  userProfile: UserProfileFragment | null;
  setUserProfile: (userProfile: UserProfileFragment) => void;
}>()((set) => ({
  userProfile: null,
  setUserProfile: (userProfile: UserProfileFragment) => set({ userProfile }),
}));

export function useUserProfile() {
  const { userProfile, setUserProfile } = useUserStore((state) => ({
    userProfile: state.userProfile,
    setUserProfile: state.setUserProfile,
  }));

  useEffect(() => {
    if (userProfile) return;

    checkIsAuthenticated().then((isAuthenticated) => {
      if (isAuthenticated) {
        loadUserProfile()
          .then((userProfile) => {
            if (userProfile) setUserProfile(userProfile);
          })
          .catch();
      }
    });
  }, []);

  return {
    userProfile,
  };
}
