import { toast } from 'sonner';

import { createTableOrder } from '@/actions/order/create-table-order';
import { createTakeAwayOrder } from '@/actions/order/create-take-away-order';
import { Order } from '@/actions/order/order-types';
import { startAnonWebPayment } from '@/actions/payment/start-anon-web-payment';
import { startUserWebPayment } from '@/actions/payment/start-user-web-payment';

import {
  Eat_Order_CreateTableOrder_Input,
  SupportedPaymentType,
} from './__generated__/graphql';
import { getAnonId, getClientType, neverHappens } from './utils';

export async function createOrderForDiningMode(
  {
    diningMode,
    input: { tableNumber, contactNumberAsE164, ...input },
  }: {
    diningMode: 'TABLE' | 'TAKE_AWAY';
    input: Omit<
      Eat_Order_CreateTableOrder_Input,
      'tableNumber' | 'contactNumberAsE164'
    > & {
      tableNumber?: string | null;
      contactNumberAsE164?: string | null;
    };
  },
  t: (key: string, defaultValue: string) => string,
): Promise<Order | undefined> {
  switch (diningMode) {
    case 'TABLE': {
      if (tableNumber == null) {
        toast.error(
          t('Toast - Table number required', 'A table number is required'),
        );
        return;
      }
      return await createTableOrder({
        anonId: getAnonId(),
        input: {
          ...input,
          tableNumber,
          clientType: getClientType(),
        },
      });
    }
    case 'TAKE_AWAY': {
      if (contactNumberAsE164 == null) {
        toast.error(
          t('Toast - Phone number required', 'A phone number is required'),
        );
        return;
      }
      return await createTakeAwayOrder({
        anonId: getAnonId(),
        input: {
          ...input,
          contactNumberAsE164,
          clientType: getClientType(),
        },
      });
    }
    default: {
      neverHappens(diningMode);
    }
  }
}

export async function initializeWebPayment({
  isAuthenticated,
  restaurantId,
  orderId,
  saveNewCreditCard,
  paymentTypeCode,
}: {
  orderId: string;
  restaurantId: string;
  saveNewCreditCard: boolean;
  paymentTypeCode: SupportedPaymentType;
  isAuthenticated: boolean;
}) {
  let paymentPageUrl;

  if (isAuthenticated) {
    const response = await startUserWebPayment({
      input: {
        orderId: orderId,
        paymentType: paymentTypeCode,
        returnUrl: `${origin}/${restaurantId}/checkout/pending?orderId=${orderId}`,
        storePaymentMeans: saveNewCreditCard,
      },
    });
    paymentPageUrl = response.paymentPageUrl;
  } else {
    const response = await startAnonWebPayment({
      input: {
        anonId: getAnonId(),
        orderId: orderId,
        paymentType: paymentTypeCode, // FIXME: Rename on backend to paymentTypeCode
        returnUrl: `${origin}/${restaurantId}/checkout/pending?orderId=${orderId}`,
      },
    });
    paymentPageUrl = response.paymentPageUrl;
  }

  if (!paymentPageUrl) {
    throw new Error('Failed to start web checkout');
  }

  window.location.href = paymentPageUrl;
}
