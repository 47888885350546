import { ExternalLink } from 'lucide-react';

import { useTranslation } from '@/i18n/client';

export function CheckoutTermsAndConditionsSection() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center py-5">
      <p className="max-w-xs text-center text-sm">
        {t(
          'Checkout - Terms and conditions 1',
          'By continuing, you agree to the',
        )}
        <br />
        <a
          href="https://www.weissearena.com/datenschutz/"
          target="_blank"
          className="text-action-blue"
        >
          {t('Checkout - Terms and conditions 2', 'Terms & Conditions')}
        </a>
        <ExternalLink className="text-action-blue -mt-0.5 ml-1 inline size-3" />
      </p>
    </div>
  );
}
