import { ChevronLeft, Info } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

import { useTranslation } from '@/i18n/client';
import { useNavigate } from '@/lib/navigation';
import { cn } from '@/lib/utils';
import { useMainStore } from '@/stores/main-store';

//TODO: Redo this component so it's not this global logic monstronsity
export function Header(props: { restaurantId: string }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const restaurantName = useMainStore((store) => store.restaurant?.name);
  const diningMode = useMainStore((store) => store.restaurant?.diningMode);

  const isEatIn = diningMode === 'TABLE';

  let label = '';

  const isMenuUrl = pathname.includes('/menu');

  if (isMenuUrl) {
    label = restaurantName ?? '';
  } else if (pathname.includes('/cart')) {
    label = t('Header cart', 'Cart');
  } else if (pathname.includes('/entry')) {
    label = t('Header item', 'Item');
  } else if (pathname.includes('/details')) {
    label = t('Header details', 'Your details');
  } else if (pathname.includes('/checkout')) {
    label = t('Header checkout', 'Checkout');
  } else if (pathname.includes('/orders')) {
    label = t('Header orders', 'All orders today');
  }

  let backUrl: string | null = '/';
  const showInstructions = pathname.endsWith('/menu');

  if (pathname.includes('/cart')) {
    backUrl = '/menu';
  } else if (pathname.includes('/item')) {
    backUrl = '/cart';
  } else if (pathname.includes('/details')) {
    backUrl = '/cart';
  } else if (pathname.includes('/checkout')) {
    if (
      pathname.includes('/success') ||
      pathname.includes('/failure') ||
      pathname.includes('/pending')
    ) {
      backUrl = null;
    } else {
      backUrl = '/details';
    }
  } else if (pathname.includes('/orders')) {
    backUrl = '/';
  } else if (pathname.includes('/instruction-steps')) {
    backUrl = '/menu';
  }

  if (label === '') {
    return null;
  }

  return (
    <div
      className={cn(
        'fixed left-0 right-0 top-0 z-[20] grid h-12 grid-cols-[60px_1fr_60px] items-center justify-between bg-white',
        !isMenuUrl && 'shadow-sm',
      )}
    >
      <div>
        {backUrl && (
          <button
            className="flex size-12 items-center justify-center"
            onClick={() => navigate(`/${props.restaurantId}${backUrl}`)}
          >
            <ChevronLeft className="size-6" />
          </button>
        )}
      </div>

      <div
        className={cn(
          'my-2 flex flex-col gap-0 text-center font-bold leading-none',
          isEatIn && 'text-lg',
        )}
      >
        {label}
        {!isEatIn && (
          <span className="text-xs font-normal">
            {diningMode === 'DELIVERY'
              ? t('Header - Delivery & Take away', 'Delivery & Take away')
              : t('Header - Take away', 'Take away')}
          </span>
        )}
      </div>

      <div>
        {showInstructions && (
          <Link
            to={'./menu/instruction-steps'}
            className="flex flex-col items-center px-4 text-sm text-action-blue"
          >
            <Info className="size-6" />
          </Link>
        )}
      </div>
    </div>
  );
}
