import { Order } from '@/actions/order/order-types';
import { graphql } from '@/lib/__generated__';
import { apolloClient } from '@/lib/apollo/apollo-client';

export async function listOrdersToday(
  anonId: string,
  restaurantId: string | null,
): Promise<Order[]> {
  const response = await apolloClient.query({
    query: graphql(`
      query Eat_Order_ListOrdersToday(
        $anonId: ID
        $input: Eat_Order_ListOrdersToday_Input!
      ) {
        Eat_Order_ListOrdersToday(anonId: $anonId, input: $input) {
          orders {
            __typename
            ...Eat_Order_Delivery
            ...Eat_Order_SelfCheckout
            ...Eat_Order_Table
            ...Eat_Order_TakeAway
          }
        }
      }
    `),
    variables: {
      anonId,
      input: { restaurantId },
    },
  });

  return response.data.Eat_Order_ListOrdersToday.orders;
}
