import { RestaurantStatus } from '@/app/shared-components/restaurant-status';
import { Eat_RestaurantFragment } from '@/lib/__generated__/graphql';

type Props = {
  restaurant: Eat_RestaurantFragment;
};

export function MenuHeader(props: Props) {
  return (
    <div className="sticky top-16 mt-16 flex w-full flex-col gap-5 px-3">
      <RestaurantStatus {...props.restaurant} />
    </div>
  );
}
