import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { listOrdersToday } from '@/actions/order/list-orders-today';
import { Order } from '@/actions/order/order-types';
import { TableIcon } from '@/components/common/icons/table-icon';
import { Link, useParamsRequired } from '@/lib/navigation';
import { getAnonId } from '@/lib/utils';
import { useMainStore } from '@/stores/main-store';

import { OrderInfo, OrderInfoCard } from '../shared-components/order-info';
import { RestaurantStatusCompact } from '../shared-components/restaurant-status';
import { ViewMenuButton } from './components/view-menu-button';

export function RestaurantPage() {
  const { restaurantId } = useParamsRequired('restaurantId');
  const [orders, setOrders] = useState<Order[]>([]);
  const { t } = useTranslation();
  const { restaurant, tableNumber } = useMainStore((state) => ({
    restaurant: state.restaurant,
    tableNumber: state.tableNumber,
  }));

  useEffect(() => {
    const load = async () => {
      const anonId = getAnonId();
      const ordersToday = await listOrdersToday(anonId, restaurantId);
      setOrders(ordersToday);
    };

    load();
  }, []);

  if (!restaurant) {
    return null;
  }

  return (
    <div className="flex h-[100svh] w-full flex-col">
      <div className="relative h-full max-h-[55vh] w-full">
        <img
          src={restaurant.heroImageUrl}
          className="absolute inset-0 h-full w-full object-cover"
          alt=""
        />
      </div>

      <div className="z-20 -mt-10 flex h-full flex-col items-center justify-between gap-2 rounded-t-lg bg-white py-5">
        <div className="flex h-full w-full flex-col justify-between px-5">
          <div className="flex w-full flex-col items-stretch gap-1.5">
            <p className="text-center text-3xl font-semibold">
              {restaurant.name}
            </p>
            <RestaurantStatusCompact {...restaurant} />
            {!orders.length && (
              <p className="text-center text-sm text-muted-foreground">
                {restaurant.diningMode === 'TABLE' &&
                  t(
                    'Home - Bring to your table',
                    'We will gladly bring your order to your table',
                  )}
              </p>
            )}
            {restaurant.diningMode === 'TABLE' && (
              <div className="flex items-center justify-center gap-1 text-sm">
                <TableIcon />
                <p>
                  {t('Home - Table', 'Table')} {tableNumber}
                </p>
              </div>
            )}

            {orders.length === 0 ? null : orders.length === 1 ? (
              <Link to={`orders?expanded=${orders[0]!.id}`}>
                <OrderInfo className="my-6" order={orders[0]!} withLinkArrow />
              </Link>
            ) : (
              <Link to={`orders`}>
                <OrderInfoCard
                  withLinkArrow
                  className="my-6"
                  icon={
                    <img src="/icons/fork-knife.svg" className="mt-1.5 h-4" />
                  }
                >
                  <h3 className="text-bold">{t('Home - Orders', 'Orders')}</h3>
                  <p className="text-sm text-muted-foreground">
                    {t('Home - All orders button', 'All orders')}
                  </p>
                </OrderInfoCard>
              </Link>
            )}
          </div>

          <div className="flex flex-col items-center gap-3">
            <ViewMenuButton restaurant={restaurant} tableNumber={tableNumber} />

            {/* FIXME: This won’t make sense within the app */}
            <p className="text-xs text-muted-foreground">
              {t('Home - Order via LAAX App 1', 'You can also order via the')}{' '}
              <a
                href="https://laax.com/laax-app"
                className="text-action-blue underline"
              >
                {t('Home - Order via LAAX App 2', 'LAAX App')}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
