import { endOfMonth, isBefore } from 'date-fns';
import { useEffect } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { loadPaymentMethods } from '@/actions/payment/load-payment-methods';
import {
  PaymentMethodFragment,
  SupportedPaymentType,
} from '@/lib/__generated__/graphql';
import { checkIsAuthenticated } from '@/lib/auth';

// FIXME: Add this alias on the backend
export type PaymentTypeCode = SupportedPaymentType;

export type PaymentType = {
  code: PaymentTypeCode;
  untranslatedName: string;
  imageUrl: string;
};

// FIXME: Load this from the backend
export const paymentTypes: PaymentType[] = [
  {
    code: 'CREDIT_CARD',
    untranslatedName: 'New credit card',
    imageUrl: '/payments/credit-card.png',
  },
  {
    code: 'TWINT',
    untranslatedName: 'TWINT',
    imageUrl: '/payments/twint.png',
  },
  {
    code: 'GOOGLEPAY',
    untranslatedName: 'Google Pay',
    imageUrl: '/payments/google-pay.png',
  },
  {
    code: 'APPLEPAY',
    untranslatedName: 'Apple Pay',
    imageUrl: '/payments/apple-pay.png',
  },
  {
    code: 'POSTFINANCE',
    untranslatedName: 'PostFinance',
    imageUrl: '/payments/post-finance.png',
  },
];

const paymentTypeCodes: PaymentTypeCode[] = paymentTypes.map(
  (paymentType) => paymentType.code,
);

export function isPaymentTypeCode(value: string): value is PaymentTypeCode {
  return paymentTypeCodes.includes(value as unknown as PaymentTypeCode);
}

const usePaymentStore = create<{
  deselectPayment: () => void;

  selectedPaymentTypeCode: PaymentTypeCode | null;
  setSelectedPaymentTypeCode: (paymentTypeCode: PaymentTypeCode) => void;

  saveNewCreditCard: boolean;
  toggleSaveNewCreditCard: () => void;

  selectedPaymentMethodId: string | null;
  setSelectedPaymentMethodId: (paymentMethodId: string) => void;

  savedPaymentMethods: PaymentMethodFragment[] | null;
  setSavedPaymentMethods: (methods: PaymentMethodFragment[]) => void;
}>()(
  persist(
    (set) => ({
      deselectPayment: () => {
        set(() => ({
          selectedPaymentTypeCode: null,
          selectedPaymentMethodId: null,
        }));
      },

      selectedPaymentTypeCode: null,
      setSelectedPaymentTypeCode: (selectedPaymentTypeCode) => {
        set(() => ({
          selectedPaymentTypeCode,
          selectedPaymentMethodId: null,
        }));
      },

      saveNewCreditCard: true,
      toggleSaveNewCreditCard: () => {
        set((state) => ({
          saveNewCreditCard: !state.saveNewCreditCard,
        }));
      },

      selectedPaymentMethodId: null,
      setSelectedPaymentMethodId: (paymentMethodId) => {
        set(() => ({
          selectedPaymentTypeCode: null,
          selectedPaymentMethodId: paymentMethodId,
        }));
      },

      savedPaymentMethods: null,
      setSavedPaymentMethods: (savedPaymentMethods) => {
        set({ savedPaymentMethods });
      },
    }),
    {
      name: 'payment-store',
      partialize: (state) => ({
        selectedPaymentTypeCode: state.selectedPaymentTypeCode,
        selectedPaymentMethodId: state.selectedPaymentMethodId,
      }),
    },
  ),
);

export function usePayment() {
  return usePaymentStore((state) => {
    const selectedPaymentType =
      state.selectedPaymentTypeCode &&
      paymentTypes.find(
        (payment) => payment.code === state.selectedPaymentTypeCode,
      );

    const selectedPaymentMethod =
      state.selectedPaymentMethodId && state.savedPaymentMethods
        ? (state.savedPaymentMethods.find(
            (savedPaymentMethod) =>
              savedPaymentMethod.id === state.selectedPaymentMethodId,
          ) ?? null)
        : null;

    const hasValidPaymentSelected =
      selectedPaymentType != null ||
      (selectedPaymentMethod && !isPaymentMethodExpired(selectedPaymentMethod));

    return {
      deselectPayment: state.deselectPayment,

      selectedPaymentType,
      setSelectedPaymentTypeCode: state.setSelectedPaymentTypeCode,

      selectedPaymentMethod,
      setSelectedPaymentMethodId: state.setSelectedPaymentMethodId,

      saveNewCreditCard: state.saveNewCreditCard,
      toggleSaveNewCreditCard: state.toggleSaveNewCreditCard,

      hasValidPaymentSelected,
    };
  });
}

export function useSavedPaymentMethods() {
  const { savedPaymentMethods, setSavedPaymentMethods } = usePaymentStore(
    (state) => ({
      savedPaymentMethods: state.savedPaymentMethods,
      setSavedPaymentMethods: state.setSavedPaymentMethods,
    }),
  );

  useEffect(() => {
    if (savedPaymentMethods) return;

    checkIsAuthenticated().then((isAuthenticated) => {
      if (isAuthenticated) {
        loadPaymentMethods()
          .then(({ savedPaymentMethods }) => {
            setSavedPaymentMethods(savedPaymentMethods);
          })
          .catch();
      }
    });
  }, []);

  return { savedPaymentMethods };
}

export function isPaymentMethodExpired(
  paymentMethod: PaymentMethodFragment,
): boolean {
  const expirationDate = endOfMonth(
    new Date(paymentMethod.expiryYear, paymentMethod.expiryMonth - 1),
  );

  return isBefore(expirationDate, new Date());
}

export function formatPaymentMethodExpiry(
  paymentMethod: PaymentMethodFragment,
) {
  const expiryMonth =
    paymentMethod.expiryMonth < 10
      ? '0' + paymentMethod.expiryMonth
      : String(paymentMethod.expiryMonth);

  return `${expiryMonth}.${paymentMethod.expiryYear}`;
}
