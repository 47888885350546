import { stripTypename } from '@apollo/client/utilities';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateDealInCart } from '@/actions/cart/update-deal';
import { calculateDealTotal } from '@/app/2-menu/components/focus-menu-deal';
import {
  DealGroup,
  DealGroupSelectionItems,
} from '@/app/shared-components/deal-group';
import { ContentSlider } from '@/components/common/content-slider';
import { DrawerNavigationHeader } from '@/components/common/drawer/drawer-nav-header';
import { ListEntry } from '@/components/common/list-entry';
import { Button } from '@/components/ui/button';
import { DrawerContent, DrawerFooter } from '@/components/ui/drawer';
import {
  Eat_Cart_DealFragment,
  Eat_Menu_Deal_Group,
} from '@/lib/__generated__/graphql';
import { rappenToFrancs } from '@/lib/utils';
import { useMainStore } from '@/stores/main-store';

export function FocusCartDeal(props: {
  restaurantId: string;
  cartDeal: Eat_Cart_DealFragment;
}) {
  const { t } = useTranslation();
  const { setCart } = useMainStore();

  const [selections, setSelections] = useState(
    props.cartDeal.groups.reduce(
      (acc, cartItem) => {
        acc[cartItem.sku] ??= cartItem.items.map((cartItem) => ({
          sku: cartItem.sku,
          quantity: cartItem.quantity,
          modifierGroups: cartItem.modifierGroups ?? [],
        }));
        return acc;
      },
      {} as Record<string, DealGroupSelectionItems[]>,
    ),
  );

  const [focusedMenuGroup, setFocusedMenuGroup] =
    useState<Eat_Menu_Deal_Group>();

  const updateSelection = async (
    selection: SetStateAction<DealGroupSelectionItems[]>,
    focusedMenuGroupSku: string,
  ) => {
    const newSelections = {
      ...selections,
      [focusedMenuGroupSku]:
        typeof selection === 'function'
          ? selection(selections?.[focusedMenuGroupSku] ?? [])
          : selection,
    };

    setSelections(newSelections);
  };

  //Save cart on unmount by registering/abusing a react effect destructor.
  //Could be made nicer by providing a stable `onDrawerClose` in drawer context.
  useEffect(
    () => () =>
      //setSelections is required to get the latest selection without re-mounting this hook.
      setSelections((selections) => {
        updateDealInCart({
          cartDealId: props.cartDeal.id,
          groups: Object.entries(selections).map(([key, value]) => ({
            sku: key,
            items: value.map((item) => ({
              sku: item.sku,
              quantity: item.quantity,
              modifierGroups: stripTypename(item.modifierGroups),
            })),
          })),
          restaurantId: props.restaurantId,
        }).then((newCart) => setCart(newCart));
        return selections;
      }),
    [],
  );

  const total = calculateDealTotal(
    selections,
    props.cartDeal.menuDeal.basePriceRappen,
    props.cartDeal.menuDeal.groups,
  );

  const isValidatedSelection =
    !focusedMenuGroup ||
    (selections.modifierGroups?.length ?? 0) >=
      (focusedMenuGroup.items.find(
        (item) =>
          item.sku ===
          (selections[focusedMenuGroup.sku] ?? []).find(
            (selection) => selection.quantity > 1,
          )?.sku,
      )?.modifierGroups?.length ?? 0);

  return (
    <DrawerContent className="h-svh" disableOverlay>
      <div className="flex-1">
        <DrawerNavigationHeader
          title={props.cartDeal.menuDeal.name}
          close={focusedMenuGroup == null}
          onBack={
            isValidatedSelection && (() => setFocusedMenuGroup(undefined))
          }
        />
        <ContentSlider
          contentIndex={focusedMenuGroup ? 1 : 0}
          axis="horizontal"
          duration={500}
        >
          <div className="px-4">
            {focusedMenuGroup ? (
              <DealGroup
                group={focusedMenuGroup}
                selection={selections[focusedMenuGroup.sku] ?? []}
                setSelection={(selection) =>
                  updateSelection(selection, focusedMenuGroup.sku)
                }
              />
            ) : (
              <DealOverview
                selections={selections}
                menuGroups={props.cartDeal.menuDeal.groups}
                onGroupSelect={setFocusedMenuGroup}
              />
            )}
          </div>
        </ContentSlider>
        <DrawerFooter className="sticky bottom-0 flex flex-row items-center justify-between border-t border-muted bg-background py-3">
          <Button
            variant={'default'}
            className="flex h-10 min-w-32 flex-col text-sm"
            disabled={!isValidatedSelection}
            onClick={() =>
              focusedMenuGroup
                ? setFocusedMenuGroup(undefined)
                : window.history.back()
            }
          >
            {focusedMenuGroup
              ? t('Cart - Item drawer - Button back', 'Back')
              : t('Cart - Item drawer - Button confirm', 'Confirm')}
          </Button>
          <p className="text-lrg text-muted-foreground">
            {rappenToFrancs(total)} CHF
          </p>
        </DrawerFooter>
      </div>
    </DrawerContent>
  );
}

function DealOverview(props: {
  menuGroups: Eat_Menu_Deal_Group[];
  selections: Record<string, DealGroupSelectionItems[]>;
  onGroupSelect(group: Eat_Menu_Deal_Group): void;
}) {
  const { t } = useTranslation();
  return props.menuGroups.map((menuGroup) => {
    const selection = props.selections[menuGroup.sku];
    const cartItems = selection
      ? menuGroup.items.filter((item) =>
          selection.find((s) => s.sku === item.sku && s.quantity > 0),
        )
      : [];

    const price =
      cartItems.length &&
      cartItems.reduce((acc, item) => acc + item.priceRappen, 0);

    return cartItems.length ? (
      <ListEntry
        name={menuGroup.name}
        description={cartItems.map((item) => item.name).join(', ')}
        imageUrl={cartItems[0]?.imageUrl}
        priceText={
          price > 0
            ? `${rappenToFrancs(price)} CHF`
            : t('Price - Included', 'Included')
        }
        key={menuGroup.sku}
        onImageClick={() => props.onGroupSelect(menuGroup)}
      />
    ) : (
      <ListEntry
        imageClassName="grayscale"
        name={menuGroup.name}
        description={t('Cart - No deal item in cart', 'Not selected')}
        priceText=""
        imageUrl={menuGroup.items[0]?.imageUrl}
        key={menuGroup.sku}
        onImageClick={() => props.onGroupSelect(menuGroup)}
      />
    );
  });
}
