import { CircleCheck } from 'lucide-react';

import { PaymentTypeName } from '@/app/5-checkout/1-start/components/payment-type-name';
import { PaymentType, PaymentTypeCode } from '@/stores/payment-store';

type Props = {
  paymentType: PaymentType;
  selected: boolean;
  onSelect: (paymentTypeCode: PaymentTypeCode) => void;
};

export function PaymentTypeSelectItem(props: Props) {
  return (
    <div className="flex items-center justify-between">
      <button
        className="flex flex-1 items-center gap-5 py-3"
        onClick={() => props.onSelect(props.paymentType.code)}
      >
        <img
          src={props.paymentType.imageUrl}
          alt={props.paymentType.untranslatedName}
          className="h-6"
        />
        <p className="text-md">
          <PaymentTypeName paymentType={props.paymentType} />
        </p>
      </button>
      {props.selected && <CircleCheck className="mr-2 size-5" />}
    </div>
  );
}
