import { TableOrder } from '@/actions/order/order-types';
import { graphql } from '@/lib/__generated__';
import { MutationEat_Order_CreateTableOrderArgs } from '@/lib/__generated__/graphql';
import { apolloClient } from '@/lib/apollo/apollo-client';

export async function createTableOrder(
  variables: MutationEat_Order_CreateTableOrderArgs,
): Promise<TableOrder | undefined> {
  const { data } = await apolloClient.mutate({
    mutation: graphql(`
      mutation Eat_Order_CreateTableOrder(
        $input: Eat_Order_CreateTableOrder_Input!
        $anonId: ID
      ) {
        Eat_Order_CreateTableOrder(input: $input, anonId: $anonId) {
          order {
            ...Eat_Order_Table
          }
        }
      }
    `),
    variables,
  });

  return data?.Eat_Order_CreateTableOrder.order;
}
