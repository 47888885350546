import { buttonVariants } from '@/components/ui/button';
import { useTranslation } from '@/i18n/client';
import { Link } from '@/lib/navigation';
import { cn } from '@/lib/utils';

// FIXME: This doesn’t use the info markdown content at all — it should either appear somewhere or it should be removed from the content model

export function InstructionStepPage() {
  const { t } = useTranslation();

  return (
    <>
      <div className="mx-5 mb-4 mt-14 flex flex-1 flex-col gap-7 overflow-y-scroll pt-3">
        <InstructionStep
          step={1}
          imageClassName="py-1"
          icon={'/icons/phone.svg'}
          description={t(
            'Instruction steps - Step 1 - Description',
            'Scan and order on your table',
          )}
        />

        <InstructionStep
          step={2}
          icon={'/icons/select-items.svg'}
          description={t(
            'Instruction steps - Step 2 - Description',
            'Select your food and add to the cart',
          )}
        />

        <InstructionStep
          step={3}
          icon={'/icons/pay.svg'}
          description={t(
            'Instruction steps - Step 3 - Description',
            'Pay your order and save the receipt',
          )}
        />

        <InstructionStep
          step={4}
          icon={'/icons/waiter.svg'}
          description={t(
            'Instruction steps - Step 4 - Description',
            "We're happy to bring your food to you!",
          )}
        />
      </div>
      <div className="flex flex-col gap-6 py-4">
        {/* <p className="px-2 text-sm">
              {t(
                "Instruction steps - Footer text 1",
                "In the meantime, you can download the"
              )}{" "}
              <Link
                href="https://laax.com/laax-app"
                target="_blank"
                className="text-action-blue underline"
              >
                {t("Instruction steps - Footer text 2 (link)", "LAAX APP")}
              </Link>{" "}
              {t(
                "Instruction steps - Footer text 3",
                "and collect the loyalty points on your order."
              )}
            </p> */}

        <Link
          to="https://laax.com/laax-app"
          className={cn(
            buttonVariants(),
            'mx-auto flex w-64 items-center gap-1 bg-laaxred',
          )}
        >
          <img
            src="/icons/laax-app-icon.svg"
            alt="step explaining mobile ordering"
            className="h-6 object-contain"
          />
          <p>{t('Instruction steps - Footer button', 'Get the LAAX APP')}</p>
        </Link>
      </div>
    </>
  );
}

function InstructionStep(props: {
  step: number;
  icon: string;
  description: string;
  imageClassName?: string;
}) {
  return (
    <div className="flex flex-col items-stretch gap-1">
      <div className="relative flex justify-center">
        <img
          src={props.icon}
          alt={''}
          className={cn(
            'h-[5rem] w-[5rem] object-contain',
            props.imageClassName,
          )}
        />
        <div className="absolute left-3 top-1/2 flex h-12 w-12 -translate-y-1/2 items-center justify-center rounded-full bg-black text-white">
          <span className="block text-2xl tabular-nums">{props.step}</span>
        </div>
      </div>

      <p className="text-center font-medium">{props.description}</p>
    </div>
  );
}
