import { CartEntry } from '@/app/5-checkout/1-start/components/cart-entry-checkout';
import { Button } from '@/components/ui/button';
import { useTranslation } from '@/i18n/client';
import { Eat_CartFragment } from '@/lib/__generated__/graphql';
import { Link } from '@/lib/navigation';

type Props = {
  restaurantId: string;
  cart: Eat_CartFragment;
};

export function CheckoutOrderItemsSection(props: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3 px-5 pb-5 pt-4">
      <h1 className="text-xl font-bold">
        {t('Checkout - Order summary section title', 'Your items')}
      </h1>

      {props.cart.entries.length === 0 ? (
        <>
          <p className="">{t('Cart empty - Title', 'Your cart is empty')}</p>
          <Button asChild variant="secondary" className="items-center">
            <Link to={`/${props.restaurantId}/menu`} replace>
              {t('Cart empty - Button text', 'Back to menu')}
            </Link>
          </Button>
        </>
      ) : (
        props.cart.entries.map((cartEntry) => (
          <CartEntry key={cartEntry.id} cartEntry={cartEntry} />
        ))
      )}
    </div>
  );
}
