import { t } from 'i18next';
import { SetStateAction, useCallback, useMemo, useState } from 'react';

import { addDealInCart } from '@/actions/cart/add-deal';
import {
  DealGroup,
  DealGroupSelectionItems,
} from '@/app/shared-components/deal-group';
import { ContentSlider } from '@/components/common/content-slider';
import { useDrawerContext } from '@/components/common/drawer/drawer-layout';
import { DrawerNavigationHeader } from '@/components/common/drawer/drawer-nav-header';
import { StepCounter } from '@/components/common/step-counter';
import { Button } from '@/components/ui/button';
import { DrawerContent, DrawerFooter } from '@/components/ui/drawer';
import {
  Eat_Menu_Deal,
  Eat_Menu_Deal_Group,
} from '@/lib/__generated__/graphql';
import { rappenToFrancs } from '@/lib/utils';
import { useMainStore } from '@/stores/main-store';

// FIXME: What is this doing here?
export function calculateDealTotal(
  selections: Record<string, DealGroupSelectionItems[] | undefined>,
  basePriceRappen: number,
  menuDealGroups: Eat_Menu_Deal_Group[],
) {
  return (
    basePriceRappen +
    Object.values(selections)
      .flat()
      .filter((selectItem) => selectItem && selectItem.quantity > 0)
      .reduce(
        (acc, selectItem) =>
          acc +
          (menuDealGroups
            .flatMap((s) => s.items)
            .find((item) => item.sku === selectItem?.sku)?.priceRappen ?? 0) *
            (selectItem?.quantity ?? 0),
        0,
      )
  );
}

type Props = {
  restaurantId: string;
  menuDeal: Eat_Menu_Deal;
};

export function FocusMenuDeal(props: Props) {
  const setCart = useMainStore((state) => state.setCart);

  const drawer = useDrawerContext();

  const [activeIndex, setActiveIndex] = useState(0);

  const currentGroup = props.menuDeal.groups[activeIndex];

  if (!currentGroup) {
    throw new Error(
      `Group ${currentGroup} does not exists within deal ${props.menuDeal.name}`,
    );
  }

  const [selections, setSelections] = useState<
    Record<string, DealGroupSelectionItems[] | undefined>
  >({});

  const groupSelection = useMemo(
    () =>
      Object.entries(selections).find(
        ([sku]) => sku === currentGroup?.sku,
      )?.[1] ?? [],
    [selections, currentGroup?.sku],
  );

  const setGroupSelection = useCallback(
    (
      value:
        | DealGroupSelectionItems[]
        | SetStateAction<DealGroupSelectionItems[]>,
    ) => {
      setSelections((selections) => ({
        ...selections,
        [currentGroup.sku]:
          typeof value === 'function'
            ? value(selections[currentGroup.sku] ?? [])
            : value,
      }));
    },
    [currentGroup.sku],
  );

  //TODO: Better validation of different combinations
  const activeGroupValid =
    !currentGroup.mustSelectAnItem ||
    selections[currentGroup.sku]?.some(
      (selection) =>
        //Check that something is selected
        selection.quantity > 0 &&
        //And if so, modifiers has been selected for all instructions.
        selection.modifierGroups.length >=
          (currentGroup.items.find((item) => item.sku === selection.sku)
            ?.modifierGroups?.length ?? 0),
    );

  const next = async () => {
    if (!activeGroupValid) {
      return;
    }

    const nextIndex = activeIndex + 1;
    if (props.menuDeal.groups[nextIndex]) {
      return setActiveIndex((index) => index + 1);
    }
    const updatedCart = await addDealInCart({
      quantity: 1,
      restaurantId: props.restaurantId,
      dealSku: props.menuDeal.sku,
      groups: Object.entries(selections).map(([groupSku, selections]) => ({
        items:
          //TODO: This is wrong and it supply modifiers as well.
          selections?.map((selection) => ({
            sku: selection.sku,
            quantity: selection.quantity,
            modifierGroups: selection.modifierGroups,
          })) ?? [],
        sku: groupSku,
      })),
    });
    setCart(updatedCart);
    drawer.close();
  };

  const total = calculateDealTotal(
    selections,
    props.menuDeal.basePriceRappen,
    props.menuDeal.groups,
  );

  const onGoBack =
    activeIndex !== 0 && (() => setActiveIndex((index) => index - 1));

  return (
    <DrawerContent className="h-svh" disableOverlay>
      <DrawerNavigationHeader
        title={props.menuDeal.name}
        close={activeIndex === 0}
        onBack={onGoBack}
      >
        <StepCounter
          className="mx-4 my-1.5"
          activeStep={activeIndex}
          numberOfSteps={props.menuDeal.groups.length}
        />
      </DrawerNavigationHeader>

      <div className="flex-1 overflow-auto">
        <ContentSlider
          contentIndex={activeIndex}
          axis={'horizontal'}
          duration={500}
          className="h-full overflow-hidden"
        >
          <DealGroup
            className="h-full overflow-y-scroll px-4"
            key={currentGroup.sku}
            group={currentGroup}
            selection={groupSelection}
            setSelection={setGroupSelection}
          />
        </ContentSlider>
      </div>

      <DrawerFooter className="flex flex-row items-center justify-between border-t border-muted py-3">
        <Button
          variant={activeGroupValid ? 'default' : 'outline'}
          disabled={!activeGroupValid}
          className="flex h-10 min-w-32 flex-col text-sm"
          onClick={next}
        >
          {t('Menu - Item drawer - Button continue', 'Continue')}
        </Button>
        <p className="text-lrg text-muted-foreground">
          {rappenToFrancs(total)} CHF
        </p>
      </DrawerFooter>
    </DrawerContent>
  );
}
