import { TakeAwayOrder } from '@/actions/order/order-types';
import { graphql } from '@/lib/__generated__';
import { MutationEat_Order_CreateTakeAwayOrderArgs } from '@/lib/__generated__/graphql';
import { apolloClient } from '@/lib/apollo/apollo-client';

export async function createTakeAwayOrder(
  variables: MutationEat_Order_CreateTakeAwayOrderArgs,
): Promise<TakeAwayOrder | undefined> {
  const { data } = await apolloClient.mutate({
    mutation: graphql(`
      mutation Eat_Order_CreateTakeAwayOrder(
        $input: Eat_Order_CreateTakeAwayOrder_Input!
        $anonId: ID
      ) {
        Eat_Order_CreateTakeAwayOrder(input: $input, anonId: $anonId) {
          order {
            ...Eat_Order_TakeAway
          }
        }
      }
    `),
    variables,
  });

  return data?.Eat_Order_CreateTakeAwayOrder.order;
}
