import { graphql } from '@/lib/__generated__';
import {
  Eat_Order_TableFragment,
  MutationEat_Order_PayWithSavedPaymentMethodArgs,
} from '@/lib/__generated__/graphql';
import { apolloClient } from '@/lib/apollo/apollo-client';

export async function payWithSavedPaymentMethod(
  variables: MutationEat_Order_PayWithSavedPaymentMethodArgs,
): Promise<{
  order: Eat_Order_TableFragment | null;
  error: { message: string } | null;
}> {
  try {
    const { data } = await apolloClient.mutate({
      mutation: graphql(`
        mutation Eat_Order_PayWithSavedPaymentMethod(
          $input: Eat_Order_PayWithSavedPaymentMethod_Input!
        ) {
          Eat_Order_PayWithSavedPaymentMethod(input: $input) {
            order {
              ...Eat_Order_Table
            }
          }
        }
      `),
      variables,
    });

    return {
      order: (data?.Eat_Order_PayWithSavedPaymentMethod.order ??
        null) as Eat_Order_TableFragment | null, // FIXME: Fix this when adding support for other dining modes
      error: null,
    };
  } catch (error) {
    return {
      order: null,
      error: error as { message: string },
    };
  }
}
