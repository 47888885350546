import { useEffect, useState } from 'react';

import { addItemInCart } from '@/actions/cart/add-item';
import { removeItemInCart } from '@/actions/cart/remove-item';
import { updateItemInCart } from '@/actions/cart/update-item';
import { ListEntry } from '@/components/common/list-entry';
import { QuantitySelector } from '@/components/common/quantity-selector';
import { Button } from '@/components/ui/button';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@/components/ui/drawer';
import { useTranslation } from '@/i18n/client';
import { Eat_Menu_Item } from '@/lib/__generated__/graphql';
import { rappenToFrancs } from '@/lib/utils';
import { useMainStore } from '@/stores/main-store';

type DrawerProps = React.ComponentProps<typeof Drawer>;

export function TeaserDrawer(props: {
  open: NonNullable<DrawerProps['open']>;
  onOpenChange: NonNullable<DrawerProps['onOpenChange']>;
  onContinue: () => void;
}) {
  const { t } = useTranslation();
  const { menu, restaurant, cart } = useMainStore((state) => ({
    cart: state.cart,
    menu: state.menu,
    restaurant: state.restaurant,
  }));

  // Track recommended entries in local state to avoid changing the list when the cart changes
  const [teasers, setTeasers] = useState<Eat_Menu_Item[] | undefined | null>();

  useEffect(() => {
    if (props.open) {
      setTeasers(cart?.teasers);
    }
  }, [props.open]);

  if (menu === null || cart === null || restaurant === null) {
    return null;
  }

  return (
    <Drawer open={props.open} onOpenChange={props.onOpenChange}>
      <DrawerContent aria-describedby="Product recommendations">
        <DrawerHeader>
          <DrawerTitle className="pt-4">
            {t('Cart summary - Teasers - Title', 'You might also like this')}
          </DrawerTitle>
          <DrawerDescription></DrawerDescription>
        </DrawerHeader>
        <div className="divide-y-2 divide-muted px-5 text-left text-base text-foreground">
          {teasers?.map((entry) => (
            <TeaserItem
              key={entry.id}
              entry={entry}
              restaurantId={restaurant.id}
            />
          ))}
        </div>
        <DrawerFooter>
          <Button
            className="flex h-12 w-full gap-2 transition-transform duration-100 active:scale-105"
            onClick={props.onContinue}
          >
            <img
              className="h-5 w-5"
              src="/icons/cart.svg"
              style={{ filter: 'invert(1)' }}
            />
            {t('Cart summary - Button - At cart page', 'Continue')}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

function TeaserItem(props: { entry: Eat_Menu_Item; restaurantId: string }) {
  const [cart, setCart] = useMainStore((state) => [state.cart, state.setCart]);
  const [quantity, setQuantity] = useState(0);

  const cartItem = cart?.entries.find((entry) => entry.sku === props.entry.sku);

  const priceRappen = cartItem ? cartItem.priceRappen : props.entry.priceRappen;

  async function incrementQuantity() {
    if (quantity === 0) {
      setQuantity(1);
      const newCart = await addItemInCart({
        restaurantId: props.restaurantId,
        itemSku: props.entry.sku,
        quantity: 1,
        modifierGroups: [],
        addedFrom: 'CART_TEASERS_DRAWER',
      });
      setCart(newCart);
    } else if (cartItem) {
      setQuantity(quantity + 1);
      const newCart = await updateItemInCart({
        restaurantId: props.restaurantId,
        id: cartItem.id,
        quantity: quantity + 1,
        modifierGroups: [],
      });
      setCart(newCart);
    }
  }

  async function decrementQuantity() {
    if (!cartItem) return;

    setQuantity(quantity - 1);
    const newCart =
      quantity === 1
        ? await removeItemInCart({
            restaurantId: props.restaurantId,
            id: cartItem.id,
          })
        : await updateItemInCart({
            restaurantId: props.restaurantId,
            id: cartItem.id,
            quantity: quantity - 1,
            modifierGroups: [],
          });
    setCart(newCart);
  }

  return (
    <ListEntry
      className="pt-3"
      {...props.entry}
      priceText={rappenToFrancs(priceRappen) + ' CHF'}
    >
      <QuantitySelector
        onIncrement={incrementQuantity}
        onDecrement={decrementQuantity}
        quantity={quantity}
        trashIconsSingleItem
      />
    </ListEntry>
  );
}
