import { ComponentProps, ReactNode } from 'react';

import { cn } from '@/lib/utils';

export function FadeFromBelow({
  delay = 0,
  children,
  ...props
}: {
  delay?: number;
  children: ReactNode;
} & ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={cn(
        'animate-fade-in-from-below fill-mode-both',
        props.className,
      )}
      style={{ animationDelay: `${delay}ms`, ...props.style }}
    >
      {children}
    </div>
  );
}
