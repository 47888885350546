import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { ChevronLeft, X } from 'lucide-react';
import { ReactNode, useEffect, useRef, useState } from 'react';

import {
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from '@/components/ui/drawer';
import { cn } from '@/lib/utils';

import { useDrawerContext } from './drawer-layout';

export function DrawerNavigationHeader(props: {
  title: string;
  close: boolean | (() => void);
  onBack?: false | (() => void);
  children?: ReactNode;
}) {
  const drawer = useDrawerContext();
  const scrollElementRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (!scrollElementRef.current) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        setIsScrolled(entries.some((entry) => !entry.isIntersecting));
      },
      { threshold: 1 },
    );

    observer.observe(scrollElementRef.current);
    return () => observer.disconnect();
  }, []);

  return (
    <div
      className={'sticky top-[-0.1px] z-20 bg-background pt-[0.1px]'}
      ref={scrollElementRef}
    >
      <DrawerHeader
        className={cn('transition-all duration-150', isScrolled && 'shadow-sm')}
      >
        {/* Needs to be set to not get erros in the console  */}
        <VisuallyHidden.Root className="hidden">
          <DrawerTitle></DrawerTitle>
          <DrawerDescription></DrawerDescription>
        </VisuallyHidden.Root>

        <div className="flex items-center">
          <button
            disabled={!props.onBack}
            className={cn(
              'p-4 transition-opacity duration-150',
              !props.onBack && 'opacity-0',
            )}
            onClick={props.onBack ? props.onBack : undefined}
          >
            <ChevronLeft />
          </button>
          <div className="flex-1 text-center">{props.title}</div>
          <button
            disabled={!props.close}
            className={cn(
              'p-4 transition-opacity duration-150',
              !props.close && 'opacity-0',
            )}
            onClick={
              typeof props.close === 'function' ? props.close : drawer.close
            }
          >
            <X />
          </button>
        </div>
        {props.children}
      </DrawerHeader>
    </div>
  );
}
