import { useState } from 'react';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Link } from '@/lib/navigation';

export function RestaurantRedirect(props: {
  restaurants: {
    restaurantId: string;
    restaurantName: string;
    usesTableNumber: boolean;
  }[];
}) {
  const [tableNumber, setTableNumber] = useState<string>('123');

  return (
    <div>
      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="tablenumber">Table number</Label>
        <Input
          id="tablenumber"
          value={tableNumber}
          onChange={(e) => setTableNumber(e.target.value)}
        />
      </div>

      {props.restaurants.map((restaurant) =>
        restaurant.usesTableNumber ? (
          <div key={restaurant.restaurantId} className="p-2">
            <Button asChild>
              <Link
                to={`/${restaurant.restaurantId}?tableNumber=${tableNumber}`}
              >
                Go to {restaurant.restaurantName} (Table {tableNumber})
              </Link>
            </Button>
          </div>
        ) : (
          <div key={restaurant.restaurantId} className="p-2">
            <Button asChild>
              <Link to={`/${restaurant.restaurantId}`}>
                Go to {restaurant.restaurantName}
              </Link>
            </Button>
          </div>
        ),
      )}
    </div>
  );
}
