import { useTranslation } from '@/i18n/client';
import { useIsAuthenticated } from '@/lib/auth';
import { PaymentType } from '@/stores/payment-store';

type Props = {
  paymentType: PaymentType;
};

export function PaymentTypeName(props: Props) {
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();

  switch (props.paymentType.code) {
    case 'CREDIT_CARD':
      return isAuthenticated
        ? t('Payment type name - New credit card', 'New credit card')
        : t('Payment type name - Credit card', 'Credit card');

    case 'APPLEPAY':
      return t('Payment type name - Apple Pay', 'Apple Pay');

    case 'GOOGLEPAY':
      return t('Payment type name - Google Pay', 'Google Pay');

    case 'POSTFINANCE':
      return t('Payment type name - PostFinance', 'PostFinance');

    case 'TWINT':
      return t('Payment type name - TWINT', 'TWINT');
  }
}
