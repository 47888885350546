import { Clock9, X } from 'lucide-react';
import { useEffect, useState } from 'react';

import { useTranslation } from '@/i18n/client';
import { cn, getShowWarningTime } from '@/lib/utils';

type Props = {
  waitingTimeMinutes: number;
  className?: string;
  compact?: boolean;
};

export function WaitingTimeBanner(props: Props) {
  const { t } = useTranslation();

  const [showWarningTime, setShowWarningTime] = useState(false);

  useEffect(() => {
    const showWarningTime = getShowWarningTime();
    setShowWarningTime(showWarningTime);
  }, []);

  if (!showWarningTime || props.waitingTimeMinutes < 20) {
    return null;
  }

  if (props.compact) {
    return (
      <div className="flex items-center justify-center gap-3 px-5 py-5">
        <Clock9 className="-mr-[0.2rem] h-6 w-6 fill-yellow-400 text-white" />
        <p className="text-sm font-semibold">
          {t(
            'Checkout - Expected waiting time',
            'Expected waiting time {{waitingTimeMinutes}} min',
            { waitingTimeMinutes: props.waitingTimeMinutes },
          )}
        </p>
      </div>
    );
  }

  return (
    <div
      className={cn(
        'relative mb-4 flex w-full gap-2 rounded-2xl border px-2 py-4',
        props.className,
      )}
    >
      <Clock9 className="-mr-[0.2rem] h-6 w-6 fill-yellow-400 text-white" />

      <div className="flex-1">
        <div className="flex w-full justify-between">
          <p className="font-semibold">
            {t('Warning time - Title', 'Wow, today is a busy day!')}
          </p>
          <button
            onClick={() => {
              setShowWarningTime(false);
              localStorage.setItem(
                'lastDismissedWarningTime',
                new Date().toISOString(),
              );
            }}
          >
            <X className="h-4 w-4" />
          </button>
        </div>

        <p className="text-sm">
          {t(
            'Warning time - Description',
            'Preparation takes about {{waitingTimeMinutes}} minutes.',
            { waitingTimeMinutes: props.waitingTimeMinutes },
          )}
        </p>
      </div>

      <div className="absolute -bottom-[6.5px] left-[40px] h-[1px] w-4 rotate-45 transform rounded-md bg-border" />
      <div className="absolute -bottom-[6.5px] left-[50.8px] h-[1px] w-4 -rotate-45 transform rounded-md bg-border" />
      <div className="absolute -bottom-[1.5px] left-[43.25px] h-[3px] w-[20.4px] transform rounded-md bg-white" />
    </div>
  );
}
