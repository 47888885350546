import { useEffect, useLayoutEffect } from 'react';
import { HeadProvider, Meta, Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useParams,
} from 'react-router-dom';
import { Toaster } from 'sonner';

import { DevToolsPage } from '@/app/0-dev-tools/dev-tools-page';
import { LoginPage } from '@/app/0-login/login-page';
import { StartPage } from '@/app/0-start/start-page';
import { RestaurantLoader } from '@/app/1-restaurant/components/restaurant-loader';
import { RestaurantPage } from '@/app/1-restaurant/restaurant-page';
import { MenuPage } from '@/app/2-menu/menu-page';
import { CartPage } from '@/app/3-cart/cart-page';
import { OrderDetailsPage } from '@/app/4-order-details/order-details-page';
import { CheckoutStartPage } from '@/app/5-checkout/1-start/checkout-start-page';
import { CheckoutPendingPage } from '@/app/5-checkout/2-pending/checkout-pending-page';
import { CheckoutDonePage } from '@/app/5-checkout/3-done/checkout-done-page';
import { OrdersPage } from '@/app/6-orders/orders-page';
import { ApolloWrapper } from '@/app/shared-components/apollo-wrapper';
import { Header } from '@/app/shared-components/header';
import { useParamsRequired } from '@/lib/navigation';
import { initPushPermissionStore } from '@/stores/push-permission-store';

import { InstructionStepPage } from './2-instruction-steps/instruction-steps';
import './globals.css';

export function App() {
  const { t } = useTranslation();

  useEffect(initPushPermissionStore, []);

  //Since lang segment is optional, all child routes has to be added twice.
  //This is thanks to genius api changes in react-router-v6
  const localizedRoutes = (
    <>
      <Route path="" Component={StartPage} />
      <Route path=":restaurantId" Component={RestaurantWrapper}>
        <Route path="" Component={RestaurantPage} />
        <Route path="menu/instruction-steps" Component={InstructionStepPage} />
        <Route path="menu/entry?/:sku?" Component={MenuPage} />
        <Route path="cart/entry?/:cartEntryId?" Component={CartPage} />
        <Route path="checkout" Component={CheckoutStartPage} />
        <Route path="checkout/pending" Component={CheckoutPendingPage} />
        <Route path="checkout/success" Component={CheckoutDonePage} />
        <Route path="orders" Component={OrdersPage} />
        <Route path="details" Component={OrderDetailsPage} />
      </Route>
    </>
  );

  return (
    <ApolloWrapper>
      <HeadProvider>
        <Title>{t('Site - Page Title', { defaultValue: 'LAAX Eat' })}</Title>
        <Meta
          name="description"
          content={t('Site - Page Description', {
            defaultValue: 'Mobile ordering for LAAX restaurants',
          })}
        />
        <BrowserRouter>
          <Routes>
            <Route path="dev-tools" Component={DevToolsPage} />
            <Route path="login" Component={LoginPage} />
            <Route path=":lang?/*" Component={LanguageChecker}>
              {localizedRoutes}
            </Route>
            {localizedRoutes}
          </Routes>
        </BrowserRouter>
        <Toaster
          position="top-center"
          toastOptions={{
            style: { top: '20px' },
            classNames: {
              error: 'bg-red-100 text-red-800',
              success: 'text-green-400',
              warning: 'text-yellow-400',
              info: 'bg-blue-400',
              closeButton: 'bg-black text-white left-[unset] -right-4',
            },
          }}
          duration={4000}
        />
      </HeadProvider>
    </ApolloWrapper>
  );
}

function LanguageChecker() {
  const { lang } = useParams();
  const { i18n } = useTranslation();
  useLayoutEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  return <Outlet />;
}

function RestaurantWrapper() {
  const { restaurantId } = useParamsRequired('restaurantId');
  return (
    <RestaurantLoader restaurantId={restaurantId}>
      <Header restaurantId={restaurantId} />
      <Outlet />
    </RestaurantLoader>
  );
}
