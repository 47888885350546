import { t } from 'i18next';
import { LoaderCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { getCart } from '@/actions/cart/get-cart';
import { getOrder } from '@/actions/order/get-order';
import { Order } from '@/actions/order/order-types';
import { CheckoutDoneButton } from '@/app/5-checkout/3-done/components/checkout-done-button';
import { OrderInfo } from '@/app/shared-components/order-info';
import { FadeFromBelow } from '@/components/common/fade-from-below';
import { OrderWaiter } from '@/components/common/icons/order-waiter';
import { useTranslation } from '@/i18n/client';
import {
  Eat_Order_Status,
  Eat_Restaurant_DiningMode,
} from '@/lib/__generated__/graphql';
import { inAppLoadActiveOrders } from '@/lib/app-integration';
import { useMainStore } from '@/stores/main-store';

function useResetOrderState(restaurantId?: string | null) {
  const { setCart, setCommentsForRestaurantId } = useMainStore();
  useEffect(() => {
    if (!restaurantId) {
      return;
    }

    // Cart should have been cleared by now. Fetch it again.
    getCart({ restaurantId }).then(setCart);

    // Clear comments, should only be used for the current order.
    setCommentsForRestaurantId('', restaurantId);
  }, [restaurantId]);
}

const PENDING_INTERVAL = 2000;
const IN_PREPARATION_INTERVAL = 10_000;

function useOrder(orderId: string | null): Order | null {
  const [order, setOrder] = useState<Order | null>(null);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    async function refreshOrder() {
      if (!orderId) return;

      const fetchedOrder = await getOrder({ orderId });

      inAppLoadActiveOrders();

      switch (fetchedOrder.__typename) {
        case 'Eat_Order_Delivery': {
          switch (fetchedOrder.deliveryOrderStatus) {
            case 'ORDER_PENDING':
              timeout = setTimeout(refreshOrder, PENDING_INTERVAL);
              break;
            case 'ORDER_IN_PREPARATION':
            case 'ORDER_OUT_FOR_DELIVERY':
              timeout = setTimeout(refreshOrder, IN_PREPARATION_INTERVAL);
              break;
            default:
              break;
          }
          break;
        }

        case 'Eat_Order_SelfCheckout': {
          switch (fetchedOrder.selfCheckoutOrderStatus) {
            case 'ORDER_PENDING':
              timeout = setTimeout(refreshOrder, PENDING_INTERVAL);
              break;
            default:
              break;
          }
          break;
        }

        case 'Eat_Order_Table': {
          switch (fetchedOrder.tableOrderStatus) {
            case 'ORDER_PENDING':
              timeout = setTimeout(refreshOrder, PENDING_INTERVAL);
              break;
            case 'ORDER_IN_PREPARATION':
              timeout = setTimeout(refreshOrder, IN_PREPARATION_INTERVAL);
              break;
            default:
              break;
          }
          break;
        }

        case 'Eat_Order_TakeAway': {
          switch (fetchedOrder.takeAwayOrderStatus) {
            case 'ORDER_PENDING':
              timeout = setTimeout(refreshOrder, PENDING_INTERVAL);
              break;
            case 'ORDER_IN_PREPARATION':
              timeout = setTimeout(refreshOrder, IN_PREPARATION_INTERVAL);
              break;
            default:
              break;
          }
          break;
        }
      }

      setOrder(fetchedOrder);
    }

    timeout = setTimeout(refreshOrder, 1000);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [orderId]);

  return order;
}

export function CheckoutDonePage() {
  const { t } = useTranslation();

  const { restaurantId } = useParams();

  const restaurant = useMainStore((store) => store.restaurant);

  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');

  const order = useOrder(orderId);

  const orderStatus = order?.status ?? 'PENDING';

  useResetOrderState(order && restaurantId);

  if (!restaurant) {
    return null;
  }

  const isTakeAway = order && order.__typename === 'Eat_Order_TakeAway';
  const isTakeAwayReadyForPickup =
    isTakeAway && order.takeAwayOrderStatus === 'ORDER_READY_FOR_PICKUP';

  return (
    <div className="mt-20 flex flex-col items-center gap-3 px-5">
      {orderStatus === 'FAILURE' ? (
        <img
          id="waiter"
          src={waiterImage[orderStatus]}
          className="my-6 h-24 object-contain"
        />
      ) : (
        <OrderWaiter
          className="my-6 h-24"
          playAnimation={order?.status === 'PENDING'}
        />
      )}
      <FadeFromBelow
        className="mt-4 flex flex-col gap-1 text-center"
        key={orderStatus}
      >
        <h3 className="text-2xl font-bold">{titles[orderStatus]}</h3>
        <p className="text-sm leading-5 text-muted-foreground">
          {subtitlesTable[restaurant.diningMode][orderStatus]}
        </p>
        {/* FIXME: This is lazy / ugly */}
        {isTakeAwayReadyForPickup && (
          <p className="mt-4 text-lg font-medium leading-5 text-slate-600">
            {t(
              'Checkout - Ready - Take away - Description',
              'Your order is ready for collection',
            )}
          </p>
        )}
      </FadeFromBelow>
      {order && orderStatus !== 'PENDING' ? (
        <>
          <FadeFromBelow delay={80} className="my-6 self-stretch">
            {/* <Link to={`/orders?expanded=${order.id}`}> */}
            <OrderInfo order={order} expanded />
            {/* </Link> */}
          </FadeFromBelow>
          <FadeFromBelow delay={160}>
            <CheckoutDoneButton restaurantId={restaurantId} />
          </FadeFromBelow>
        </>
      ) : (
        <LoaderCircle
          strokeWidth={1}
          className="my-6 h-20 w-20 animate-spin opacity-30"
        />
      )}
    </div>
  );
}

const titles: Record<Eat_Order_Status, string> = {
  SUCCESS: t('Checkout - Success - Title Sucess', 'Thanks for your order!'),
  PENDING: t('Checkout - Success - Title pending', 'Almost there...'),
  FAILURE: t('Failure - Failed order - Title', 'Your order failed.'),
};

const subtitlesTable: Record<
  Eat_Restaurant_DiningMode,
  Record<Eat_Order_Status, string>
> = {
  TABLE: {
    SUCCESS: t(
      'Checkout - Success - Description',
      'We try to get your order to your table as quickly as possible.',
    ),
    PENDING: t(
      'Checkout - Pending - Processing payment',
      'We are processing your payment, please wait a moment...',
    ),
    FAILURE: t(
      'Failure - Failed - Description',
      'Something has gone wrong with your order. Please contact the restaurant staff or try again.',
    ),
  },
  TAKE_AWAY: {
    SUCCESS: t('Checkout - Success - Take away - Description', ''),
    PENDING: t(
      'Checkout - Pending - Take away - Processing payment',
      'We are processing your payment, please wait a moment...',
    ),
    FAILURE: t(
      'Failure - Failed - Take away - Description',
      'Something has gone wrong with your order. Please contact the restaurant or try again.',
    ),
  },

  //TODO: Add unique translations for take-away when implementing
  DELIVERY: {
    SUCCESS: t(
      'Checkout - Success - Description',
      'We try to get your order to your table as quickly as possible.',
    ),
    PENDING: t(
      'Checkout - Pending - Processing payment',
      'We are processing your payment, please wait a moment...',
    ),
    FAILURE: t(
      'Failure - Failed - Description',
      'Something has gone wrong with your order. Please contact the restaurant staff or try again.',
    ),
  },
};

const waiterImage: Record<Eat_Order_Status, string> = {
  PENDING: '/icons/waiter-pending.svg',
  SUCCESS: '/icons/waiter.svg',
  FAILURE: '/icons/waiter-error.svg',
};
