import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

import { checkWebPayment } from '@/actions/payment/check-web-payment';
import { FullScreenLoader } from '@/app/shared-components/full-screen-loader';
import { useTranslation } from '@/i18n/client';
import { useNavigate, useParamsRequired } from '@/lib/navigation';
import { cn } from '@/lib/utils';

const MAX_CHECKS_BEFORE_FAILURE = 5;

export function CheckoutPendingPage() {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');

  const { restaurantId } = useParamsRequired('restaurantId');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [paymentCheckCount, setPaymentCheckCount] = useState(0);

  useEffect(() => {
    if (paymentCheckCount >= MAX_CHECKS_BEFORE_FAILURE) {
      toast.error(t('Toast - Payment failed', 'Payment failed'));
      navigate(`/${restaurantId}/checkout/success?orderId=${orderId}`, {
        replace: true,
      });
    }
    const load = async () => {
      if (!orderId) {
        toast.error(t('Toast - Order not found', 'Your order was not found'));
        return;
      }
      const responseStatus = await checkWebPayment({ orderId });

      if (!responseStatus) {
        throw new Error('Could not get payment status');
      }

      if (responseStatus.status === 'PENDING') {
        return setPaymentCheckCount((count) => count + 1);
      }

      if (responseStatus.status === 'ABORTED') {
        toast.error(
          responseStatus.message ||
            t('Toast - Payment failed', 'Payment aborted'),
          { duration: 5000 },
        );
        navigate(`/${restaurantId}/checkout`, { replace: true });
      } else if (responseStatus.status === 'FAILED') {
        toast.error(
          responseStatus.message ||
            t('Toast - Payment failed', 'Payment failed'),
          { duration: 5000 },
        );
        navigate(`/${restaurantId}/checkout/success?orderId=${orderId}`, {
          replace: true,
        });
      } else if (
        responseStatus.status === 'AUTHORIZED' ||
        responseStatus.status === 'CAPTURED'
      ) {
        navigate(`/${restaurantId}/checkout/success?orderId=${orderId}`);
      }
    };

    //Don't wait for first poll, then 1500ms for subsequent queries.
    const timeout = setTimeout(load, paymentCheckCount === 0 ? 0 : 1500);
    return () => clearTimeout(timeout);
  }, [paymentCheckCount]);

  return (
    <FullScreenLoader className={cn(paymentCheckCount < 1 && 'opacity-0')} />
  );
}
