import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { auth } from '@/lib/auth';
import { Link } from '@/lib/navigation';
import { useUserProfile } from '@/stores/user-profile-store';

export function LoginPage() {
  const [status, setStatus] = useState<
    'LOADING' | 'AUTHENTICATED' | 'UNAUTHENTICATED'
  >('LOADING');

  const [searchParams, setSearchParams] = useSearchParams();
  const { userProfile } = useUserProfile();

  useEffect(() => {
    const login = searchParams.get('code')
      ? auth.handleRedirectCallback()
      : Promise.resolve();

    login.then(() => {
      auth.isAuthenticated().then((isLoggedIn) => {
        if (isLoggedIn) auth.getTokenSilently().catch();
        setStatus(isLoggedIn ? 'AUTHENTICATED' : 'UNAUTHENTICATED');
      });
      setSearchParams();
    });
  }, []);

  if (status === 'LOADING') {
    return 'Loading...';
  }

  if (status === 'AUTHENTICATED') {
    return (
      <div className="flex flex-col gap-2 p-8">
        <div className="pb-4">
          {userProfile ? (
            <p className="text-lg">Logged in as: {userProfile.email}</p>
          ) : (
            <p className="text-lg">Logged in</p>
          )}
        </div>
        <LogOutButton />
        <MenuButton />
      </div>
    );
  }

  if (status === 'UNAUTHENTICATED') {
    return (
      <div className="flex flex-col gap-2 p-8">
        <LoginButton />
        <MenuButton />
      </div>
    );
  }

  return null;
}

function LoginButton() {
  return (
    <Button
      onClick={async () => {
        await auth.loginWithRedirect({
          authorizationParams: {
            redirect_uri: `${window.location.origin}/login`,
          },
        });
      }}
    >
      Login
    </Button>
  );
}

function LogOutButton() {
  return (
    <Button
      onClick={async () => {
        await auth.logout();
      }}
    >
      Log out
    </Button>
  );
}

function MenuButton() {
  return (
    <Button variant="secondary" asChild>
      <Link to="/">Go to menu</Link>
    </Button>
  );
}
