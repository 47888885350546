import { create } from 'zustand';

interface PushPermissionStoreType {
  pushAvailable: boolean;
  pushPermissionGranted: boolean | null;
  canAskForPushPermissionAgain: boolean | null;
  setPushPermissionGranted: (granted: boolean) => void;
  setCanAskForPushPermissionAgain: (canAsk: boolean) => void;
}

export const usePushPermissionStore = create<PushPermissionStoreType>(
  (set) => ({
    pushAvailable:
      'checkPushPermission' in window &&
      typeof window.checkPushPermission === 'function' &&
      'requestPushPermission' in window &&
      typeof window.requestPushPermission === 'function',

    pushPermissionGranted: null,
    canAskForPushPermissionAgain: true,
    setPushPermissionGranted: (granted) =>
      set({ pushPermissionGranted: granted }),
    setCanAskForPushPermissionAgain: (canAsk) =>
      set({ canAskForPushPermissionAgain: canAsk }),
  }),
);

declare global {
  interface Window {
    onPushPermissionChange: (permissionResponse: unknown) => void;
  }
}

export function initPushPermissionStore() {
  window['onPushPermissionChange'] = (permissionResponseString: unknown) => {
    try {
      if (typeof permissionResponseString === 'string') {
        const { granted, canAskAgain } = JSON.parse(permissionResponseString);

        // Update Zustand store with the new permission state
        usePushPermissionStore.getState().setPushPermissionGranted(granted);
        usePushPermissionStore
          .getState()
          .setCanAskForPushPermissionAgain(canAskAgain);
      }
    } catch {
      // Badly formed permission response string; nothing we can do
    }
  };

  if (
    'checkPushPermission' in window &&
    typeof window.checkPushPermission === 'function'
  ) {
    window.checkPushPermission();
  }
}

export function requestPushPermission() {
  if (
    'requestPushPermission' in window &&
    typeof window.requestPushPermission === 'function'
  ) {
    window.requestPushPermission();
  }
}
