import { PhoneIcon, User, Utensils } from 'lucide-react';

import { TableIcon } from '@/components/common/icons/table-icon';
import { useTranslation } from '@/i18n/client';
import { Eat_RestaurantFragment } from '@/lib/__generated__/graphql';

export function CheckoutOrderDetailsSection(props: {
  restaurant: Eat_RestaurantFragment;
  tableNumber: string | null;
  nameOnReceipt: string;
  phoneNumnber: string;
}) {
  const { t } = useTranslation();

  return (
    <div className="mx-5 my-2 flex flex-col divide-y-1 divide-muted">
      <div className="flex flex-row items-center py-2.5">
        <div className="w-12">
          <Utensils className="size-5" />
        </div>
        <p className="text-md font-medium">{props.restaurant.name}</p>
      </div>

      {props.restaurant.diningMode === 'TABLE' && (
        <div className="flex flex-row items-center py-2.5">
          <div className="-ml-0.5 w-12">
            <TableIcon />
          </div>
          <p className="text-md font-medium">
            {t('Checkout - Table', 'Table')} {props.tableNumber}
          </p>
        </div>
      )}

      {props.restaurant.diningMode !== 'TABLE' && (
        <div className="flex flex-row items-center py-2.5">
          <div className="w-12">
            <PhoneIcon className="size-5" />
          </div>
          <p className="text-md font-medium">{props.phoneNumnber}</p>
        </div>
      )}

      <div className="flex flex-row items-center py-2.5">
        <div className="w-12">
          <User className="size-5" />
        </div>
        <p className="text-md font-medium">{props.nameOnReceipt}</p>
      </div>
    </div>
  );
}