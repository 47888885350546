import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerOverlay,
  DrawerTitle,
} from '@/components/ui/drawer';
import { cn } from '@/lib/utils';

const DrawerContext = createContext({ close: () => {} });
export const useDrawerContext = () => useContext(DrawerContext);

export function DrawerLayout(props: {
  children: ReactNode;
  drawer: ReactNode;
}) {
  const drawerActive = props.drawer != null;

  const [isOpen, setIsOpen] = useState(drawerActive);

  useEffect(() => {
    setIsOpen(drawerActive);
  }, [drawerActive]);

  const context = useMemo(
    () => ({
      close: () => setIsOpen(false),
    }),
    [],
  );

  const onClose = useCallback(() => {
    setTimeout(() => {
      if (drawerActive) window.history.back();
    }, 20);
  }, [drawerActive]);

  return (
    <>
      {props.children}
      <DrawerContext.Provider value={context} key="drawerContext">
        <Drawer key="drawer" onClose={onClose} open={isOpen}>
          <DrawerOverlay
            onClick={() => setIsOpen(false)}
            className={cn(!drawerActive && 'opacity-0')}
          />
          {drawerActive ? (
            props.drawer
          ) : (
            // Vaul needs something as default content in drawer not to break on back navigation.
            <DrawerContent className="h-0 opacity-0" disableOverlay>
              <DrawerHeader>
                {/* Needs to be set to not get erros in the console  */}
                <VisuallyHidden.Root className="hidden">
                  <DrawerTitle></DrawerTitle>
                  <DrawerDescription></DrawerDescription>
                </VisuallyHidden.Root>
              </DrawerHeader>
            </DrawerContent>
          )}
        </Drawer>
      </DrawerContext.Provider>
    </>
  );
}
