import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { ComponentProps, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Order } from '@/actions/order/order-types';
import { cn, rappenToFrancs } from '@/lib/utils';

type Props = {
  expanded?: boolean;
  order: Order;
  onExpand?(): void;
  withLinkArrow?: boolean;
} & ComponentProps<'div'>;

export function OrderInfo({ order, onExpand, expanded, ...props }: Props) {
  const { t } = useTranslation();
  const [animateItems] = useAutoAnimate({ duration: 100 });

  const isTakeAway = order.__typename === 'Eat_Order_TakeAway';
  const isTakeAwayInPreparation =
    isTakeAway && order.takeAwayOrderStatus === 'ORDER_IN_PREPARATION';

  return (
    <OrderInfoCard
      {...props}
      onClick={onExpand}
      className={cn(
        'relative flex gap-1.5 rounded-lg border p-3 shadow-sm',
        props.className,
      )}
      icon={
        isTakeAwayInPreparation ? (
          <OrderPendingDot />
        ) : (
          <img
            className="mt-[0.15rem] h-5 w-5 object-contain"
            src={`/icons/${order.status === 'SUCCESS' ? 'success.svg' : 'fail.svg'}`}
          />
        )
      }
    >
      {onExpand && (
        <div className="absolute right-0 top-0 p-3">
          <ChevronDown
            className={cn(
              'text-action-blue transition-transform',
              expanded && 'rotate-180',
            )}
          />
        </div>
      )}
      <div className="flex-1">
        <h3 className="font-bold">{order.restaurantName}</h3>
        {isTakeAwayInPreparation && (
          <h4 className="text-sm">
            {t('Order - In preparation', 'Order in preparation')}
          </h4>
        )}
        <ul
          className="my-[0.05rem] flex flex-col gap-[0.05rem] text-sm text-muted-foreground"
          ref={animateItems}
        >
          <li>
            {t('Order', 'Order')}: {order.orderNumber}
          </li>
          {'collectionCode' in order && (
            <li>
              {t('Order - Collection code', 'Pickup Code')}:{' '}
              {order.collectionCode}
            </li>
          )}
          <li>{new Date(order.createdAt).toLocaleString()}</li>
          {expanded && (
            <>
              {'tableNumber' in order &&
                typeof order.tableNumber === 'string' && (
                  <li>
                    {t('Home - Table', 'Table')}: {order.tableNumber}
                  </li>
                )}
              <li className={cn(order.items.length && 'mb-2')}>
                {rappenToFrancs(order.orderTotalRappen)} CHF
              </li>
              {order.items.map((item, index) => {
                return (
                  <li key={index}>
                    {item.quantity} × {item.name}
                  </li>
                );
              })}
            </>
          )}
        </ul>
      </div>
    </OrderInfoCard>
  );
}

export function OrderInfoCard({
  icon,
  withLinkArrow,
  ...props
}: {
  icon: ReactNode;
  withLinkArrow?: boolean;
} & ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={cn(
        'relative flex gap-1.5 rounded-lg border p-3 shadow-sm',
        props.className,
      )}
    >
      {icon}
      <div className="flex-1">{props.children}</div>
      {withLinkArrow && (
        <div className="absolute right-0 top-0 flex h-full items-center p-3 text-action-blue">
          <ChevronRight />
        </div>
      )}
    </div>
  );
}

export function ExpandableOrderInfo(
  props: Omit<Props, 'collapsable' | 'expanded'> & { expanded?: boolean },
) {
  const [expanded, setExpanded] = useState(props.expanded ?? false);
  return (
    <OrderInfo
      {...props}
      onExpand={() => setExpanded((prev) => !prev)}
      expanded={expanded}
    />
  );
}

function OrderPendingDot() {
  return (
    <span className="relative my-1 flex h-4 w-4">
      <span className="absolute inset-1 animate-ping rounded-full bg-action-blue opacity-90"></span>
      <span className="absolute inset-1 rounded-full bg-action-blue"></span>
    </span>
  );
}
