import { PaymentBrand } from '@/lib/__generated__/graphql';

type Props = {
  brand: PaymentBrand;
};

export function PaymentBrandIcon(props: Props) {
  switch (props.brand) {
    case 'AMEX':
      return <img src="/payments/amex.png" alt={props.brand} className="h-6" />;
    case 'DINERS':
      return (
        <img
          src="/payments/diners-club.png"
          alt={props.brand}
          className="h-6"
        />
      );
    case 'MAESTRO':
      return (
        <img src="/payments/maestro.png" alt={props.brand} className="h-6" />
      );
    case 'MASTERCARD':
      return (
        <img src="/payments/mastercard.png" alt={props.brand} className="h-6" />
      );
    case 'POSTCARD':
      return (
        <img
          src="/payments/post-finance.png"
          alt={props.brand}
          className="h-6"
        />
      );
    case 'TWINT':
      return (
        <img src="/payments/twint.png" alt={props.brand} className="h-6" />
      );
    case 'VISA':
      return <img src="/payments/visa.png" alt={props.brand} className="h-6" />;
    default:
      return (
        <img
          src="/payments/credit-card.png"
          alt={props.brand}
          className="h-6"
        />
      );
  }
}
