import { ComponentProps, ReactNode, useMemo } from 'react';

import { useDelayedValue } from '@/lib/hooks';
import { cn } from '@/lib/utils';

export function ContentSlider({
  duration = 300,
  contentIndex,
  axis,
  ...props
}: {
  children: ReactNode;
  contentIndex: number;
  axis: 'vertical' | 'horizontal';
  duration?: number;
} & ComponentProps<'div'>) {
  const value = useMemo(
    () => ({
      oldChildren: props.children,
      oldContentIndex: contentIndex,
    }),
    [props.children, contentIndex, axis],
  );
  const { oldChildren, oldContentIndex } = useDelayedValue(value, duration);
  const screenDiff = contentIndex - oldContentIndex;
  const isVertical = axis === 'vertical';
  return (
    <div {...props} className={cn('no-scrollbar relative', props.className)}>
      <div
        style={{ animationDuration: `${duration}ms` }}
        className={cn(
          'h-full',
          screenDiff > 0 &&
            (isVertical ? 'animate-slide-in-top' : 'animate-slide-in-right'),
          screenDiff < 0 &&
            (isVertical ? 'animate-slide-in-bottom' : 'animate-slide-in-left'),
        )}
      >
        {props.children}
      </div>
      {!!screenDiff && (
        <div
          style={{ animationDuration: `${duration}ms` }}
          className={cn(
            'absolute inset-0 h-full overflow-hidden fill-mode-forwards',
            screenDiff > 0 &&
              (isVertical ? 'animate-slide-out-top' : 'animate-slide-out-left'),
            screenDiff < 0 &&
              (isVertical
                ? 'animate-slide-out-bottom'
                : 'animate-slide-out-right'),
          )}
        >
          {oldChildren}
        </div>
      )}
    </div>
  );
}
