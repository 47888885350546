import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import {
  Eat_CartFragment,
  Eat_Menu,
  Eat_RestaurantFragment,
} from '@/lib/__generated__/graphql';

interface MainStoreType {
  nameOnReceipt: string;
  setNameOnReceipt: (name: string) => void;

  tableNumber: string | null;
  setTableNumber: (tableNumber: string) => void;

  commentsByRestaurantId: Record<string, string>;
  setCommentsForRestaurantId: (comments: string, restaurantId: string) => void;

  restaurant: Eat_RestaurantFragment | null;
  setRestaurant: (restaurant: Eat_RestaurantFragment) => void;

  menu: Eat_Menu | null;
  setMenu: (menu: Eat_Menu) => void;

  cart: Eat_CartFragment | null;
  setCart: (cart: Eat_CartFragment) => void;

  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
}

export const useMainStore = create<MainStoreType>()(
  persist(
    (set) => ({
      nameOnReceipt: '',
      setNameOnReceipt: (nameOnReceipt) => set({ nameOnReceipt }),

      tableNumber: null as string | null,
      setTableNumber: (tableNumber) => set({ tableNumber }),

      commentsByRestaurantId: {},
      setCommentsForRestaurantId: (comments, restaurantId) =>
        set((state) => ({
          commentsByRestaurantId: {
            ...state.commentsByRestaurantId,
            [restaurantId]: comments,
          },
        })),

      restaurant: null,
      setRestaurant: (restaurant) => set({ restaurant }),

      menu: null,
      setMenu: (menu) => set({ menu }),

      cart: null,
      setCart: (cart) => set({ cart }),

      phoneNumber: '',
      setPhoneNumber: (phoneNumber) => set({ phoneNumber }),
    }),
    {
      // FIXME: For how long should these be persisted?
      name: 'main-store',
      partialize: (state) => ({
        nameOnReceipt: state.nameOnReceipt,
        tableNumber: state.tableNumber,
        commentsByRestaurantId: state.commentsByRestaurantId,
        phoneNumber: state.phoneNumber,
      }),
    },
  ),
);
