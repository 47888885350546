import { Link } from 'react-router-dom';

import { Button, buttonVariants } from '@/components/ui/button';
import { useTranslation } from '@/i18n/client';
import {
  inAppCanNavigateToProfile,
  inAppNavigateToProfile,
} from '@/lib/app-integration';
import { cn } from '@/lib/utils';

export function CheckoutDoneButton(props: { restaurantId?: string }) {
  const { t } = useTranslation();

  return inAppCanNavigateToProfile ? (
    <Button
      className={cn(buttonVariants(), 'bg-laaxred')}
      onClick={inAppNavigateToProfile}
    >
      {t('Checkout - Success - Done', 'View orders')}
    </Button>
  ) : (
    <Link
      to={props.restaurantId ? `/${props.restaurantId}` : '/'}
      className={cn(buttonVariants(), 'bg-laaxred')}
    >
      {t('Checkout - Success - Button back to menu', 'Back to the start')}
    </Link>
  );
}
