import { graphql } from '@/lib/__generated__';
import { MutationEat_Order_StartUserWebPaymentArgs } from '@/lib/__generated__/graphql';
import { apolloClient } from '@/lib/apollo/apollo-client';

export async function startUserWebPayment(
  variables: MutationEat_Order_StartUserWebPaymentArgs,
) {
  const { data } = await apolloClient.mutate({
    mutation: graphql(`
      mutation Eat_Order_StartUserWebPayment(
        $input: Eat_Order_StartUserWebPayment_Input!
      ) {
        Eat_Order_StartUserWebPayment(input: $input) {
          paymentPageUrl
        }
      }
    `),
    variables,
  });

  return {
    paymentPageUrl: data?.Eat_Order_StartUserWebPayment.paymentPageUrl,
  };
}
