import { graphql } from '@/lib/__generated__';
import { apolloClient } from '@/lib/apollo/apollo-client';

export async function loadUserProfile() {
  const { data } = await apolloClient.query({
    query: graphql(`
      query LoadUser {
        guest {
          profile {
            ...UserProfile
          }
        }
      }
    `),
  });

  return data.guest.profile;
}
