import cn from 'clsx';
import { useEffect, useRef, useState } from 'react';

import { Eat_Menu_Section } from '@/lib/__generated__/graphql';

type Props = {
  sections: Eat_Menu_Section[];
};

export function MenuSectionTabs(props: Props) {
  const [selectedSectionId, setSelectedSectionId] = useState(
    props.sections[0]?.id,
  );

  const scrollingToTab = useRef<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const togglerRef = useRef<HTMLDivElement>(null);

  // Track when sections are scrolled into view and select them
  useEffect(() => {
    const container = containerRef.current;
    if (container == null) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (!scrollingToTab.current) {
          for (const entry of entries) {
            if (entry.isIntersecting) {
              setSelectedSectionId(entry.target.id);
            }
          }
        }
      },
      {
        rootMargin: `-${container.offsetHeight}px 0% -${window.innerHeight - container.offsetHeight}px 0%`,
      },
    );

    for (const section of props.sections) {
      const element = document.getElementById(section.id);
      if (element) observer.observe(element);
    }

    return observer.disconnect.bind(observer);
  }, [props.sections]);

  // Scroll to selected tab and mark it
  useEffect(() => {
    const heading = document.getElementById(
      `header-section-${selectedSectionId}`,
    );

    if (!heading || !togglerRef.current) {
      return;
    }

    containerRef.current?.scrollTo({
      left: heading?.offsetLeft - 15,
      behavior: 'smooth',
    });
    togglerRef.current.style.transform = `translateX(${heading.offsetLeft}px) scaleX(${heading.clientWidth})`;
  }, [selectedSectionId]);

  return (
    <div
      ref={containerRef}
      className={cn(
        'no-scrollbar sticky top-9 z-10 overflow-y-hidden overflow-x-scroll bg-white pt-5',
      )}
    >
      <div className="flex w-full">
        {props.sections.map((section) => (
          <button
            key={section.id}
            className={cn(
              'text-md origin-bottom whitespace-nowrap px-3 py-2 transition-all duration-300',
              section.id === selectedSectionId
                ? 'text-laaxred opacity-100'
                : 'opacity-70',
            )}
            onClick={() => {
              const firstMenuItem = document
                .getElementById(section.id)
                ?.querySelector('a');

              if (firstMenuItem) {
                //When scrolling to a section, we need to disable the intersectionobserver from highlighting the tabs in between.
                scrollingToTab.current = true;
                scrollTo({
                  top:
                    firstMenuItem?.offsetTop -
                    ((containerRef.current?.scrollHeight ?? 0) +
                      // this value should match top-9 of container position.
                      36),
                  behavior: 'smooth',
                });
                setSelectedSectionId(section.id);

                // We should now re-enable the behaviour of tab marking on scroll,
                // Since scroll takes a browser specific while because of "smooth", we need to wait an arbitrary amount
                setTimeout(() => (scrollingToTab.current = false), 500);
              }
            }}
          >
            <div id={`header-section-${section.id}`}>{section.name}</div>
          </button>
        ))}
      </div>

      <div
        ref={togglerRef}
        className={cn(
          'absolute bottom-0 left-0 h-[3px] w-[1px] origin-bottom-left bg-laaxred transition-all duration-300',
        )}
      />
    </div>
  );
}
